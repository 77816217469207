import { type Action, createActionsHook, createStateHook, createStore } from 'react-sweet-state';
import type { CoordinationClientType } from '@atlassiansox/engagekit-ts';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

export const havePulsesEnded = () => {
	return !!localStorage.getItem(
		PERSISTED_KEYS_ON_SERVER.PERSISTED_LOOM_CROSS_SELL_DISCOVERABILITY_PULSES_HAVE_ENDED,
	);
};

export const endPulses = () => {
	return localStorage.setItem(
		PERSISTED_KEYS_ON_SERVER.PERSISTED_LOOM_CROSS_SELL_DISCOVERABILITY_PULSES_HAVE_ENDED,
		true,
	);
};

type State = {
	/**
	 * The id of the entrypoint that currently has an active pulse
	 */
	entryPointId: string | null;
	/**
	 * The id of the most recently started discoverability pulse EP message
	 */
	messageId: string | null;
};

const initialState: State = {
	entryPointId: null,
	messageId: null,
};

const actions = {
	start:
		(entryPointId: string, messageId: string): Action<State> =>
		({ setState }) => {
			setState({ entryPointId, messageId });
		},
	stop:
		(coordinationClient: CoordinationClientType): Action<State> =>
		({ getState, setState }) => {
			const { messageId } = getState();
			if (messageId) {
				void coordinationClient.stop(messageId);
			}
			if (!havePulsesEnded()) {
				endPulses();
			}
			setState({ entryPointId: null, messageId: null });
		},
};

const Store = createStore({
	initialState,
	actions,
	name: 'LoomEntryPointsDiscoverabilityPulse',
});

export const useIsPulseActive = createStateHook(Store, {
	selector: (state, entryPointId: string) => state.entryPointId === entryPointId,
});

export const useDiscoverabilityPulseActions = createActionsHook(Store);
