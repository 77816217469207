import React, { useCallback, useContext, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { LinkButton } from '@atlaskit/button/new';
import type { PopupProps } from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	ExperienceTrackerContext,
	LOOM_CROSS_SELL_EXPERIENCE,
} from '@confluence/experience-tracker';

import { useDiscoverabilityPulseActions } from '../DiscoverabilityPulse/useDiscoverabilityPulse';
import { useLoomCrossJoinUrl } from '../useLoomCrossJoinUrl';

import {
	BaseLoomPopupCard,
	type EntryPointId,
	type TriggerProps as BaseTriggerProps,
} from './BaseLoomPopupCard';

const sourceAdType = 'crossJoin';

const i18n = defineMessages({
	crossJoinCTA: {
		id: 'loom-utils.loom-cross-sell-popup-card.crossJoinCTA',
		defaultMessage: 'Try now',
		description: 'text on button that launches a cross-join flow for Loom',
	},
});

type TriggerProps = BaseTriggerProps & {
	onClick: () => void;
};

type LoomCrossJoinPopupCardProps = {
	description: string;
	entryPointId: EntryPointId;
	placement?: PopupProps['placement'];
	shouldRenderToParent?: boolean;
	// action subject id of the entrypoint the popup is triggered from
	sourceActionSubjectId: string;
	title: string;
	triggerLink: (href: string, triggerProps: TriggerProps) => ReactNode;
};

export const LoomCrossJoinPopupCard: React.FC<LoomCrossJoinPopupCardProps> = ({
	description,
	entryPointId,
	placement,
	shouldRenderToParent,
	sourceActionSubjectId,
	title,
	triggerLink,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();

	const crossJoinHref = useLoomCrossJoinUrl();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { stopPulse } = useDiscoverabilityPulseActions();

	const onCrossJoinSuccess = useCallback(() => {
		void stopPulse();

		experienceTracker.succeed({
			name: LOOM_CROSS_SELL_EXPERIENCE,
			attributes: {
				sourceComponent: entryPointId,
				adType: sourceAdType,
			},
		});
	}, [entryPointId, experienceTracker, stopPulse]);

	const onTryNowButtonClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'tryNow',
				source: 'loomCrossJoinPopupCard',
				attributes: {
					adType: sourceAdType,
					entrypoint: entryPointId,
				},
			},
		}).fire();

		onCrossJoinSuccess();
	}, [createAnalyticsEvent, entryPointId, onCrossJoinSuccess]);

	const onCrossJoinTriggerClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: sourceActionSubjectId,
				source: sourceActionSubjectId,
				attributes: {
					adType: sourceAdType,
					entrypoint: entryPointId,
				},
			},
		}).fire();

		onCrossJoinSuccess();
	}, [createAnalyticsEvent, entryPointId, onCrossJoinSuccess, sourceActionSubjectId]);

	return (
		<BaseLoomPopupCard
			actions={
				<LinkButton
					appearance="discovery"
					href={crossJoinHref}
					onClick={onTryNowButtonClick}
					rel="noreferrer"
					target="_blank"
				>
					{intl.formatMessage(i18n.crossJoinCTA)}
				</LinkButton>
			}
			description={description}
			entryPointId={entryPointId}
			placement={placement}
			shouldRenderToParent={shouldRenderToParent}
			title={title}
			trigger={(popupTriggerProps) =>
				triggerLink(crossJoinHref, {
					...popupTriggerProps,
					onClick: onCrossJoinTriggerClick,
				})
			}
			sourceAdType={sourceAdType}
		/>
	);
};
