import { useCallback, useEffect, useState } from 'react';

import { useCoordinationClient } from '@confluence/engagement-provider';
import { Attribution } from '@confluence/error-boundary';
import { getMonitoringClient } from '@confluence/monitoring';

export const useSkippableCoordination = (
	messageId: string,
	skip: boolean = false,
	withChoreographer = true,
) => {
	const [isMessageActive, setisMessageActive] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const coordinationClient = useCoordinationClient(withChoreographer);

	useEffect(() => {
		async function startMessage() {
			try {
				const _isMessageActive = await coordinationClient.start(messageId);
				setisMessageActive(_isMessageActive);
			} catch (err) {
				getMonitoringClient().submitError(err, {
					attribution: Attribution.CONTENPLATES,
				});
			}
			setLoading(false);
		}

		if (skip) {
			return;
		}

		void startMessage();
	}, [coordinationClient, messageId, skip]);

	const stop = useCallback(
		async (force: boolean = false) => {
			if (!force && !isMessageActive) {
				return;
			}

			try {
				await coordinationClient.stop(messageId);
				setisMessageActive(false);
			} catch (err) {
				getMonitoringClient().submitError(err, {
					attribution: Attribution.CONTENPLATES,
				});
			}
		},
		[isMessageActive, coordinationClient, messageId],
	);

	return [isMessageActive, stop, skip ? false : isLoading] as const;
};
