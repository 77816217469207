import { createStore, createHook, type Action } from 'react-sweet-state';

interface State {
	blankets: Record<string, boolean>;
	isPolling: boolean;
}

const initialState: State = {
	blankets: {},
	isPolling: false,
};

const actions = {
	showBlanket:
		(entryPointId: string): Action<State> =>
		({ setState, getState }) => {
			const { blankets } = getState();
			/**
			 * entryPointId is added to manage the visibility of loading blankets for specific entry points.
			 * This allows the application to show loading blankets individually for different parts of the application,
			 * identified by their unique entryPointId
			 */
			setState({ blankets: { ...blankets, [entryPointId]: true } });
		},
	hideBlanket:
		(): Action<State> =>
		({ setState }) => {
			/**
			 * There won't be cases(at least for now) that we only want to hide ONE blanket for a specific entry point.
			 * So, for simplicity, we reset all the blankets when "hideBlanket" is called.
			 */
			setState({ blankets: {}, isPolling: false });
		},
	setIsPollingStatus:
		(isPolling: boolean): Action<State> =>
		({ setState }) =>
			setState({ isPolling }),
} as const;

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'confluence-loom-utils.loom-recorder-loading-blanket',
	initialState,
	actions,
});

export const useLoomRecorderLoadingBlanket = createHook(store);

/**
 * @param entryPointId
 * @returns object containing whether the blanket is shown and whether it is actively polling
 */
export const useIsLoomRecorderLoadingBlanketShown = (entryPointId: string) => {
	const [{ blankets, isPolling }] = useLoomRecorderLoadingBlanket();
	const isBlanketShown = !!blankets[entryPointId];
	return { isBlanketShown, isPolling };
};
